import {
  autoserialize,
  Deserialize,
  Serialize,
  autoserializeAs,
} from 'cerialize';
import {
  Customer,
  LedgerNumber,
  InvoiceVAT,
  Profile,
  EmailText,
} from '@/models';
import { Currency } from '@/lib/currency';

class ExternalInitCustomers {
  @autoserialize email: string;
  @autoserialize name: string;
  @autoserializeAs(Customer) match: Customer | null;
  @autoserializeAs(Customer) options: Customer[];
  @autoserialize info: { [key: string]: any };
}

class ExternalInitLedgers {
  @autoserialize match: Map<number, number>;
  @autoserializeAs(LedgerNumber) options: LedgerNumber[];
}

class ExternalInitVatcodes {
  @autoserialize match: Map<number, number>;
  @autoserializeAs(InvoiceVAT) options: InvoiceVAT[];
}

class ExternalInitProfiles {
  @autoserializeAs(Profile) default: Profile;
  @autoserializeAs(Profile) options: Profile[];
}

class ExternalInitEmail {
  @autoserializeAs(EmailText) options: EmailText[];
}

export class LightspeedItem {
  id: string;
  amount: number;
  productName: string;
  unitPrice: number;
  vatPercentage: number;
}

export class UntillItem {
  Id: number;
  ArticleId: number;
  ItemNumber: number;
  Kind: number;
  Quantity: number;
  SinglePrice: number;
  Price: number;
  Discount: number;
  Vat: number;
  VatPercent: number;
  Text: string;
  HqId: string;
  Description: string;
}

export class LightspeedRetailItem {
  itemID: number;
  unitQuantity: number;
  unitPrice: number;
  calcLineDiscount: number;
  Item: {
    description: string;
  };
}

export class LightspeedKSeriesItem {
  id: number;
  quantity: number;
  unitAmount: number;
  itemName: string;
}

export class ExternalInit<T> {
  @autoserializeAs(ExternalInitCustomers) customers: ExternalInitCustomers;
  @autoserializeAs(ExternalInitLedgers) ledger: ExternalInitLedgers;
  @autoserializeAs(ExternalInitProfiles) profiles: ExternalInitProfiles;
  @autoserializeAs(ExternalInitVatcodes) vat: ExternalInitVatcodes;
  @autoserializeAs(ExternalInitEmail) emailText: ExternalInitEmail;
  @autoserialize currency: Currency | undefined;
  @autoserialize vatInclusive: boolean | undefined;
  @autoserialize items: T[];

  static deserialize<T>(json: Record<string, unknown>): ExternalInit<T> {
    return Deserialize(json, ExternalInit);
  }

  public serialize(): Record<string, unknown> {
    return Serialize(this, ExternalInit);
  }
}
