import { CreatorService } from '.';
import {
  Customer,
  Invoice,
  InvoiceReceiver,
  InvoiceTextEmail,
  InvoiceVAT,
  LedgerNumber,
  Product,
  Profile,
} from '@/models';

import TnfConfig from '@/config';
import axios from 'axios';
import { ExternalInit, LightspeedItem } from '@/models/external/ExternalInit';

export default class LightspeedCreatorService implements CreatorService {
  public data: ExternalInit<LightspeedItem>;
  private company: string;
  private receipt: string;
  private jwt: string;

  private get urlParams() {
    return {
      company: this.company,
      receipt: this.receipt,
      jwt: this.jwt,
    };
  }

  public async setup(
    company: string,
    receipt: string,
    jwt: string,
  ): Promise<void> {
    this.company = company;
    this.receipt = receipt;
    this.jwt = jwt;

    const { data } = await axios.get(
      `${TnfConfig.api.base}/external/lightspeed/init`,
      {
        params: {
          ...this.urlParams,
        },
      },
    );

    this.data = ExternalInit.deserialize(data.data);
  }

  public async getLogo(logo: string): Promise<any> {
    const { data } = await axios.get(
      `${TnfConfig.api.base}/external/lightspeed/logo/${logo}`,
      {
        params: {
          ...this.urlParams,
        },
      },
    );
    return data.data;
  }

  public async listReceivers(): Promise<InvoiceReceiver[]> {
    // make sure we only return customers if data is available (truthy)
    return (
      this.data && this.data.customers.options.map((e) => e.toInvoiceReceiver())
    );
  }

  public async listProfiles(): Promise<Profile[]> {
    // make sure we only return profiles if data is available (truthy)
    return this.data && this.data.profiles.options;
  }

  public async searchProducts(keyword: string): Promise<Product[]> {
    void keyword;
    return [];
  }

  public async listLedgerNumbers(): Promise<LedgerNumber[]> {
    // make sure we only return ledgers if data is available (truthy)
    return this.data && this.data.ledger.options;
  }

  public async listVatCodes(): Promise<InvoiceVAT[]> {
    // make sure we only return vats if data is available (truthy)
    return this.data && this.data.vat.options;
  }

  public async emailText(emailTextId: number): Promise<InvoiceTextEmail> {
    // make sure we only return emailText if data is available (truthy)
    const text =
      this.data &&
      this.data.emailText.options.find((e) => +e.id === +emailTextId);

    if (!text) {
      throw new Error('Emailtext not found');
    }

    return InvoiceTextEmail.fromProfileEmailText(text);
  }

  public async createInvoice(invoice: Invoice): Promise<string> {
    const { data } = await axios.post(
      `${TnfConfig.api.base}/external/lightspeed`,
      invoice.serialize(),
      {
        params: {
          ...this.urlParams,
        },
      },
    );
    return data.data;
  }

  public async sendMail(id: string, text: InvoiceTextEmail): Promise<void> {
    await axios.post(
      `${TnfConfig.api.base}/external/lightspeed/${id}/send/mail`,
      text.serialize(),
      {
        params: {
          ...this.urlParams,
        },
      },
    );
  }

  public async createCustomer(customer: Customer): Promise<number> {
    const { data } = await axios.post(
      `${TnfConfig.api.base}/external/lightspeed/customer`,
      customer.serialize(),
      {
        params: {
          ...this.urlParams,
        },
      },
    );
    return data.data.id;
  }

  public async updateCustomer(customer: Customer): Promise<void> {
    await axios.put(
      `${TnfConfig.api.base}/external/lightspeed/customer/${customer.id}`,
      customer.serialize(),
      {
        params: {
          ...this.urlParams,
        },
      },
    );
  }
}
