export interface LightspeedReceiptItem {
  UUID: string;
  name: string;
  amount: number;
  itemId: number;
  productId: number;
  price: number;
  priceWithoutVat: number;
  PLU: string;
}

export interface LightspeedReceipt {
  receiptId: string;
  receiptUUID: string;
  companyId: number;
  currentUserId: number;
  ownerUserId: number;
  floorId: number;
  tableId: string;
  parentId: string;
  vatInclusive: boolean;
  total: number;
  totalDue: number;
  items: LightspeedReceiptItem[];
}

export interface LightspeedCustomer {
  customerId: number;
  customerUUID: string;
  email: string;
  firstName: string;
  lastName: string;
  telephone: string;
}

export interface LightspeedRestaurantInterface {
  callbacks: {
    startPayment: (context: {
      requestId: string;
      customer: LightspeedCustomer;
      receipt: LightspeedReceipt;
    }) => void;
    cancelPayment: (context: { requestId: string }) => void;
  };
  actions: {
    confirmPayment: (confirmation: {
      amount: number;
      tip: number;
      requestId: string;
    }) => void;
    cancelPayment: (cancellation: { requestId: string }) => void;
  };
}

const dummyLightspeedReceiptItem = () => ({
  UUID: '1',
  name: 'item',
  amount: 1,
  itemId: 1,
  productId: 1,
  price: 1,
  priceWithoutVat: 1,
  PLU: '1',
});

const dummyLightspeedReceipt = () => ({
  receiptId: '1',
  receiptUUID: '1',
  companyId: 1,
  currentUserId: 1,
  ownerUserId: 1,
  floorId: 1,
  tableId: '1',
  parentId: '1',
  vatInclusive: true,
  total: 1,
  totalDue: 1,
  items: [dummyLightspeedReceiptItem()],
});

const dummyLightspeedCustomer = () => ({
  customerId: 1,
  customerUUID: '1',
  email: '1',
  firstName: '1',
  lastName: '1',
  telephone: '1',
});

const dummyLightspeedRestaurant = () => ({
  callbacks: {
    startPayment: ({ requestId, receipt, customer }) =>
      Promise.resolve({ requestId, receipt, customer }),
  },
  actions: {
    // eslint-disable-next-line
    confirmPayment: (_c) => {},
  },
});

export const registerDummyLightspeedLibrary = (): void => {
  const w = window as any;
  w.LightspeedRestaurant = dummyLightspeedRestaurant();
  window.addEventListener('load', () =>
    w.LightspeedRestaurant.callbacks.startPayment({
      requestId: '1',
      receipt: dummyLightspeedReceipt(),
      customer: dummyLightspeedCustomer(),
    }),
  );
};
